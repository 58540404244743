import Router from 'vue-router'
import Vue from 'vue'

//导入页面组件
const LoginView = () => import('../views/LoginView');
const LayoutView = () => import('../views/LayoutView');
const GoodsList = () => import('../views/goods/GoodsList');
const MemberList = () => import('../views/member/MemberList');
const MakeList = () => import('../views/make/MakeList');
const FeedbackList = () => import('../views/feedback/FeedbackList');
const OrderList = () => import('../views/order/OrderList');
const BannerList = () => import('../views/banner/BannerList');
const FileListView = () => import('../views/file/FileList')
const fileCatalog =() =>import('../views/fileCatalog/fileCatalog')
const ItemsPage = () => import('../views/banner/EntryPage')
const stuCode = () => import('../views/member/student/StudentList')
// const Board = () => import('../views/board/Board')


Vue.use(Router)

const routes = [
    {
        path: '/login',
        component: LoginView,
        meta: {
            requireLogin: false
        }
    },
    {
        path: '/',
        component: LayoutView,
        // redirect: '/board',
        meta: {
            // 当前页面需要登录才能访问
            requireLogin: true
        },
        children:[
            {
                path: '/goods',
                component: GoodsList,
                meta: {
                    requireLogin: true,
                }
            },
            // {
            //     path: '/board',
            //     component: Board,
            //     meta: {
            //         requireLogin: true,
            //     }
            // },
            {
                path: '/member',
                component: MemberList,
                meta: {
                    requireLogin: true,
                }
            },
            {
                path: '/stuCode',
                component: stuCode,
                meta: {
                    requireLogin: true,
                }
            },
            {
                path: '/make',
                component: MakeList,
                meta: {
                    requireLogin: true,
                }
            },
            {
                path: '/order',
                component: OrderList,
                meta: {
                    requireLogin: true,
                }
            },
            {
                path: '/banner',
                component: BannerList,
                meta: {
                    requireLogin: true,
                }
            },
            {
                path: '/items',
                component: ItemsPage,
                meta: {
                    requireLogin: true,
                }
            },
            {
                path: '/file',
                component: FileListView,
                meta: {
                    requireLogin: true
                }
            },
            {
                path: '/filecatalog',
                component: fileCatalog,
                meta: {
                    requireLogin: true
                }
            },
            {
                path: '/feedback',
                component: FeedbackList,
                meta: {
                    requireLogin: true
                }
            },
        ]
    },
    
];

const router = new Router({
    mode: 'hash',
    routes
});
router.beforeEach(function (to, from, next) {
    // 当前页面需要登录访问
    
    if (to.meta.requireLogin) {
        // 读取登录信息
        console.log(123);
        let token  = JSON.parse(localStorage.getItem('adminToken'));
        console.log(token);
        if (!token) {
            // 跳转到登录页面
            next('/login');
        }
    }
    next()
})
export default router