import axios from 'axios'
import Vue from 'vue';
import qs from 'qs'
import router from '@/router';
import ElementUI from 'element-ui';

const _axios = axios.create({
    // baseURL: 'http://192.168.10.7:8000'
    baseURL: 'https://api.tanyuezhe.cn'
});

_axios.interceptors.request.use(function (request) {
    if (request.url == '/login/') {
        request.data = qs.stringify(request.data)
    } else {
        var tokenData = JSON.parse(localStorage.getItem('adminToken'))
        if (tokenData) {
            request.headers.Authorization = tokenData['token_type'] + ' ' + tokenData['access_token']
        } else {
            ElementUI.Notification({
                title: '提示',
                message: '登录状态异常,请重新登录',
                type: 'error'
            })
            console.log("token失效");
            router.replace('/login')
            return
        }
    }
    return request
});

_axios.interceptors.response.use(
    response => {
        if (response.status == 401) {
            localStorage.removeItem("adminToken");
            ElementUI.Notifiction({
                title: '提示',
                message: response.detail,
                type: 'error'
            })
            router.replace('/login')
            return Promise.reject(response);
        }
        return response.data;
    },
    error => {
        if (error.response.status == 401) {
            localStorage.removeItem("adminToken");
            ElementUI.Notifiction({
                title: '提示',
                message: error.response.detail,
                type: 'error'
            })
            router.replace('/login')
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$axios = _axios

export default _axios