import axios from "../../plugins/axios";
export default {
    namespaced: true,
    state() {
        return {
            // 商品数据
            memberdata: {
                page: 1,
                limit: 20,
                list: [],
                total: 0
            },

        }
    },
    mutations: {
        // 初始化会员列表
        MemberInit(state, memberdata) {
            state.memberdata = memberdata;
            console.log('调用初始化商品');
            console.log(state.memberdata);
        },
    },
    // 请求接口的动作
    actions: {
        // 请求数据接口（getmember）为请求的方法名
        getMember(context) {
            console.log(this);
            let page = this.state.member.memberdata.page
            let limit = this.state.member.memberdata.limit
            console.log('这里的page是：' + page + 'limit是：' + limit);
            var url = `/user_list?page=${page}&limit=${limit}`
            console.log(url);
            axios.get(url).then(res => {
                if (res.code == 200) {
                    console.log('请求会员列表数据的返回值：');
                    console.log(res);
                    var resData = res.data.list
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                        const element = resData[index];
                        element.create_time = element.create_time.replace('T', ' ')
                    }
                    res.data.list = resData
                    context.commit('MemberInit', res.data)
                }
            })
        }
    }
}