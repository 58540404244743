import axios from "../../plugins/axios";
export default {
    namespaced: true,
    state() {
        return {
            // 预约列表数据
            MakerData: {
                page: 1,
                limit: 20,
                list: [],
                total: 0
            },
        }
    },
    mutations: {
        // 初始化商品列表
        MakerInit(state, MakerData) {
            state.MakerData = MakerData;
            console.log('调用初始化商品');
            console.log(state.MakerData);
        },
    },
    actions: {
        getMake(context) {
            console.log(this);
            let page = this.state.make.MakerData.page
            let limit = this.state.make.MakerData.limit
            var url = `/booking?page=${page}&limit=${limit}`
            axios.get(url).then(res => {
                if (res.code == 200) {
                    console.log('请求预约列表的返回值：');
                    console.log(res);
                    var resData = res.data.list
                    console.log('------------------哈哈哈哈哈');
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                        const element = resData[index];
                        element.create_time = element.create_time.replace('T', ' ')
                    }
                    res.data.list = resData
                    context.commit('MakerInit', res.data)
                    console.log(res.data);
                } else {
                    console.log("返回的不是200");
                }
            })
        }
    }
}