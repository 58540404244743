import Vue from 'vue'
import Vuex from 'vuex'
import goods from './modules/goods'
import member from './modules/member'
import make from './modules/make'
import order from './modules/order'
import banner from './modules/banner'
import file from './modules/file'
import fileCatalog from './modules/fileCatalog'
import items from './modules/items'
import student from './modules/student'
import feedback from './modules/feedback'
Vue.use(Vuex)
// 创建数据存储对象
const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        goods,
        member,
        make,
        order,
        banner,
        file,
        fileCatalog,
        items,
        student,
        feedback
    }
})

export default store;