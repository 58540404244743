import axios from '../../plugins/axios';
export default {
    namespaced: true,
    state() {
        return {
            itemsData: [],
        }
    },
    mutations: {
        // 初始化商品列表
        itemsInit(state, itemsData) {
            state.itemsData = itemsData;
        },
    },
    actions: {
        // 请求banner列表
        // params={page,size}
        getItemsAction(context,) {
            axios.get(`/items/`).then(res => {
                if (res.code === 200) {
                    var resData = res.data
                    console.log('------------------哈哈哈哈哈');
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                        const element = resData[index];
                        element.update_time = element.update_time.replace('T', ' ')

                    }
                    res.data = resData
                    // 更新商品列表
                    context.commit('itemsInit', res.data);
                }
            });
        },
    }
}